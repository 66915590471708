<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" sm="6" lg="3">
          <v-sheet>
            <v-row>
              <div style="height: 60px; width: 100%" class="d-flex secondary px-4 pt-3 pb-6">
                <KawasakiLogo @click.native="handleLogoClick" :role="$route.name !== 'login' && 'button'"/>
              </div>
              <div style="width: 100%">
                <hr style="height: 2.82px" class="primary"/>
                <hr style="height: 1.41px" class="black"/>
                <hr style="height: 6.35px" class="primary"/>
                <hr style="height: 2.12px" class="black"/>
                <hr style="height: 11.29px" class="primary"/>
              </div>
            </v-row>
            <v-row style="background-color: #ffffff">
              <router-view/>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script lang="js">
import KawasakiLogo from '@/images/KawasakiLogo.vue';

export default {
  name: 'Authorisation',
  components: { KawasakiLogo },
  methods: {
    handleLogoClick() {
      if (this.$route.name !== 'login') this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped lang="scss">
hr {
  width:            100%;
  border:           0 none;
  background-color: #555555;
}
</style>
